import $ from "jquery";

/*
 * JsonFileを取得して返却
 */
export default class GetJson {
  constructor() {}

  get(file) {
    const d = $.Deferred();

    $.ajax({
      scriptCharset: 'UTF-8',
      url: file,
      dataType: 'json',
      type: 'GET',
      cache: false,
    }).done(data => {
      d.resolve(data);
    }).fail((jqXHR, textStatus, errorThrown) => {
      d.reject(jqXHR, textStatus, errorThrown);
    });

    return d.promise();
  }
}
