import $ from "jquery";
import _ from "underscore";
import slick from "slick-carousel";

export default class normalSlide {
  constructor() {
    this.sliderStopFlg = false;
    this.init();
  }

  init() {
    this.initSlider();
  }

  initSlider() {
    const targetElm = document.getElementsByClassName('js-normalSlide')[0];
    const mainSlideElm = targetElm.getElementsByClassName('js-normalSlideTarget')[0];
    const slickOpt = {
      slidesToShow: 1,
      fade: true,
      autoplay: true,
      dots: true,
      arrows: false,
      autoplaySpeed: 3000,
      initialSlide: 0,
      pauseOnFocus: false,
      pauseOnHover: false
    };
    const slider = $(mainSlideElm).slick(slickOpt);

    const stopElm = targetElm.getElementsByClassName('js-normalSlideStop')[0];
    const prevElm = targetElm.getElementsByClassName('js-normalSlidePrev')[0];
    const nextElm = targetElm.getElementsByClassName('js-normalSlideNext')[0];

    const stopSlider = () => {
      if (!this.sliderStopFlg) {
        slider.slick('slickSetOption', 'autoplay', false).slick('slickPause');
      }
      stopElm.classList.add('-stop');
      this.sliderStopFlg = true;
    }

    const startSlider = () => {
      if (this.sliderStopFlg) {
        slider.slick('slickSetOption', 'autoplay', true).slick('slickPlay');
      }
      stopElm.classList.remove('-stop');
      this.sliderStopFlg = false;
    }

    this.debouncedOnKvStop = _.debounce(() => {
      if (this.sliderStopFlg) {
        startSlider();
      } else {
        stopSlider();
      }
    }, 0);
    stopElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnKvStop();
    });

    this.debouncedOnKvPrev = _.debounce(() => {
      slider.slick('slickPrev');
    }, 0);
    prevElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnKvPrev();
    });

    this.debouncedOnKvNext = _.debounce(() => {
      slider.slick('slickNext');
    }, 0);
    nextElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnKvNext();
    });
  }
}

