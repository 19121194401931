import "./polyfill.js";
import $ from "jquery";
import contact from "./subPage/Contact";
import anchor from "./subPage/Anchor";
import zoomImg from "./subPage/ZoomImg";
import customSlide from "./subPage/CustomSlide";
import overlay from "./subPage/Overlay";
import heightSet from "./subPage/HeightSet";
import allheightSet from "./subPage/AllHeightSet";
import accordion from "./subPage/Accordion";
import sideNav from "./subPage/SideNav";
import siteMap from "./subPage/SiteMap";
import normalSlide from "./subPage/NormalSlide";
import modal from "./subPage/Modal";
import serviceBlockImage from "./topPage/ServiceBlockImage";
import simpleAccordion from "./subPage/SimpleAccordion";
import pageSearchForm from "./subPage/PageSearchForm";
import newsList from "./subPage/NewsList";
import fixedDirect from "./subPage/FixedDirect";
$(() => {
  new anchor();
  new contact();

  if (document.getElementsByClassName('js-zoom').length) {
    new zoomImg();
  }

  if (document.querySelectorAll('.js-pikupSlide li').length > 2) {
    document.getElementsByClassName('js-pikupSlide')[0].classList.add('is-active');
    new customSlide('js-pikupSlide', '10%');
  }

  if (document.getElementsByClassName('js-scrollOverlay').length) {
    new overlay();
  }

  if (document.getElementsByClassName('js-acdTrigger').length) {
    new accordion();
  }

  if (document.getElementsByClassName('js-sideNav').length) {
    new sideNav('js-sideNav');
  } else {
    if (document.getElementsByClassName('sideMenu').length) {
      new sideNav('js-sideNav');
    }
  }

  if (document.getElementsByClassName('js-siteMap').length) {
    new siteMap('js-siteMap');
  }

  if (document.getElementsByClassName('js-normalSlide').length) {
    new normalSlide();
  }

  if (document.getElementsByClassName('js-modalTrigger').length) {
    new modal();
  }

  if (document.getElementsByClassName('js-serviceBlockImage').length) {
    new serviceBlockImage();
  }

  if (document.getElementsByClassName('js-simpleAcdTrigger').length) {
    new simpleAccordion();
  }

  if (document.getElementsByClassName('js-pageSearchForm').length) {
    new pageSearchForm();
  }

  if (document.getElementsByClassName('js-news').length) {
    new newsList();
  }

  if (document.getElementsByClassName('js-fixedDirect').length) {
    new fixedDirect();
  }
});

window.addEventListener('load', e => {
  if (document.getElementsByClassName('js-heightSet').length) {
    new heightSet();
  }

  if (document.getElementsByClassName('js-allHeightSet').length) {
    new allheightSet();
  }
});