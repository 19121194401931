import $ from "jquery";
export default class NewsList {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    /**
     * savedQueryData URL引数を取得
     * @return {Object} name,valueをデータ型で保持
     */
     var savedQueryData = function(){
      var data={};
      if(location.search){
        var arr=location.search.substring(1,location.search.length).split('&');
        for(var i=0;i<arr.length;i++){data[arr[i].split('=')[0]] = arr[i].split('=')[1];}
      }
      return data;
    };

    var $contentBodyMain = $('.js-news');
    var $pageList = $('.js-yearList', $contentBodyMain);
    var $newsTitle = $('.js-newsTitle', $contentBodyMain);
    var $newsList = $('.js-newsList', $contentBodyMain);
    var $detailInformations = $('.js-newsCategory input[type=checkbox]', $contentBodyMain);
    var queryData = savedQueryData();
    var categoryArr = ['km', 'kd', 'kt', 'hm', 'hb', 'hd', 'ah', 'sf', 'si'];
    var category = savedQueryData().l;

    // 掲載場所種別が指定なし、もしくは掲載場所種別の値が不正の場合
    if ((category === undefined) || ($.inArray(category, categoryArr) < 0)){ category = 'km'; }

    //パンくず切り替え
    $('.js-changeCategory .' + category).addClass('is-active');

    /**
     * setLocationId
     * 対象の要素のID属性をクエリーにもとづいて書き換える
     * @param jQueryオブジェクト化した要素
     * @retrun
     */
    var setLocationId = function($element) {
      // 掲載場所種別の値が不正の場合（指定なしは除く）、「km（個人）」とする
      if ((queryData.l !== undefined) && ($.inArray(queryData.l, [/*'km', */'kd', 'kt', 'hm', 'hb', 'hd', 'ah', 'sf', 'si']) < 0)) {
        queryData.l = undefined;
      }

      // 「お知らせ一覧」で掲載対象が「km（個人）」以外は絞込機能を非表示とする
      if (queryData.l !== undefined) {
        $('.js-newsCategory, .js-newsCategoryTitle').css({
          'display': 'none'
        });
      }

      // HTMLの初期IDが「person」のため、「km、kd、kt」は処理対象外
      var id = '';
      if ($.inArray(queryData.l, ['hm', 'hb', 'hd']) >= 0) {
        id = 'company';
      }
      else if ($.inArray(queryData.l, ['ah']) >= 0) {
        id = 'about';
      }
      else if ($.inArray(queryData.l, ['si']) >= 0) {
        id = 'ir';
      }
      else if ($.inArray(queryData.l, ['sf']) >= 0) {
        id = 'other';
      }

      if (id !== '') {
        $element.attr({
          'id': id
        });
      }
    };

    /**
     * getHashYear
     * ハッシュに含まれる年データを取得する
     * @param
     * @return numberYear 年データ（含まない場合は0を返す)
     */
    var getHashYear = function() {
      var numberYear = 0;
      var hash = location.hash.substr(1);
      if (!!hash) {
        $.each(hash.split(','), function(index, value) {
          var _value = value.split('=');
          if (_value[0] === 'y') {
            numberYear = Number(_value[1]);
            numberYear = isNaN(numberYear) ? 0 : numberYear;
            return false;
          }
        });
      }

      return numberYear;
    };

    /**
     * setHashYear
     * hashの年データを置き換える
     * @param year 年データ
     * @return
     */
    var setHashYear = function(year) {
      var currentYear = 'y=' + year;

      var hashList = [];
      var hash = location.hash.substr(1);
      //.split(',');
      if (!!hash) {
        hashList = hash.split(',');

        $.each(hashList, function(index, value) {
          var _value = value.split('=');
          if (_value[0] === 'y') {
            hashList[index] = currentYear;
            return false;
          }
        });
      }
      else {
        hashList.push(currentYear);
      }

      // location.hashの書換え
      location.hash = hashList.join(',').replace(/^,/, '');
    };

    /**
     * buildNewsList
     * ニュース一覧構築
     * @param year 西暦4桁
     * @retrun
     */
    var buildNewsList = function(year) {
      var $items = $('<div class="newsList" style="" data-year="' + year + '">');
      $items.append($('<ul>'));

      $.ajax({
        url: year + '.xml',
        dataType: 'xml',
        cache: false,
        error: function(xhr, status) {
          $newsList.html('').append($items);
          // contentTitle はHTMLページに記述されているグローバル変数
          $newsList.append($('<p>').text('対象の' + contentTitle + 'はありませんでした。'));
          $newsTitle.html('<h2 class="h2">' + year + '</h2>');
        },
        success: function(data, status, xhr) {
          var detailInformations = getDetailInformations();

          if (status === 'success') {
            var $data = $(data);

            $.each($('article', $data), function(index, value) {
              var $article = $(this);
              var attrLocation = $article.attr('location');
              var iconType = $('articon', $article).text();
              var date = $('viewdate', $article).text();
              var href = $('title', $article).attr('href');
              var target = ($('title', $article).attr('target') === '_blank') ? ' target="_blank"' : '';
              var win_class = ($('title', $article).attr('class') !== 'undefined') ? $('title', $article).attr('class') : '';
              ;
              var title = $('title', $article).text();
              var marks = [];
              var marks_tag = '';

              // 掲載場所種別の値が不正の場合（指定なしは除く）、「km（個人）」とする
              if ((queryData.l !== undefined) && ($.inArray(queryData.l, [/*'km',
               * */'kd', 'kt', 'hm', 'hb', 'hd', 'ah', 'sf', 'si']) < 0)) {
                queryData.l = undefined;
              }

              // 掲載場所種別の指定はあるが、掲載対象となっていない場合、タグ生成をせず、次記事の処理へ進む
              if ((!!queryData.l) && (!attrLocation.match(queryData.l))) {
                return true;
              }

              // お知らせ種別の指定はあるが、掲載対象となっていない場合、タグ生成をせず、次記事の処理へ進む
              if ((detailInformations !== '') && (!detailInformations.match(new RegExp(iconType)))) {
                return true;
              }

              var icon = '';
              switch (iconType) {
                case 'i01':
                  // お知らせ
                  icon = 'iconInfo';
                  break;
                case 'i02':
                  // 全てのお客さま
                  icon = 'iconAll';
                  break;
                case 'i03':
                  // 個人のお客さま
                  icon = 'iconKojin01';
                  break;
                case 'i04':
                  // 個人　商品・サービス
                  icon = 'iconKojin02';
                  break;
                case 'i05':
                  // 個人　キャンペーン
                  icon = 'iconKojin03';
                  break;
                case 'i06':
                  // 個人　セミナー・相談会
                  icon = 'iconKojin06';
                  break;
                case 'i07':
                  // 北洋ダイレクト
                  icon = 'iconKojin04';
                  break;
                case 'i08':
                  // 北洋投信ダイレクト
                  icon = 'iconKojin05';
                  break;
                case 'i09':
                  // 法人・個人事業主のお客さま
                  icon = 'iconHojin01';
                  break;
                case 'i10':
                  // ビジネスダイレクト
                  icon = 'iconHojin02';
                  break;
                case 'i11':
                  // でんさいサービス
                  icon = 'iconHojin03';
                  break;
                case 'i12':
                  // 株主・投資家の皆さま
                  icon = 'iconIr';
                  break;
                case 'i13':
                  // CSR
                  icon = 'iconCsr';
                  break;
                case 'i14':
                  // セキュリティ・金融犯罪
                  icon = 'iconSecurity';
                  break;
                case 'i15':
                  // 店舗・ATM
                  icon = 'iconAtm';
                  break;
                default:
                  icon = '';
              }

              $.each($('marklist mark', $article), function(index, value) {
                var mark = $(this).text();
                marks.push(mark);
                marks_tag += '<span class="' + mark.substr(0, 1).toUpperCase() + '"></span>';
              });

              var item = $('<li class="' + icon + '"><p><strong>' + date + '</strong></p><em class="' + marks.join(' ') + '"><a href="' + href + '" class="' + win_class + '"' + target + '>' + title + '</a>' + marks_tag + '</em></li>');

              $('ul', $items).append(item);
            });

            $newsList.html('').append($items);
            $newsTitle.html('<h2 class="h2">' + year + '</h2>');

            if ($('li', $items).length === 0) {
              // contentTitle はHTMLページに記述されているグローバル変数
              $newsList.append($('<p>').text('対象の' + contentTitle + 'はありませんでした。'));
            }
          }
        }
      });
    };

    /**
     * yearRadioSet
     * 年選択ラジオボタン カレント変更
     * @param year 西暦4桁
     * @retrun
     */
     var yearRadioSet = function(year) {
      $('.js-year[value="' + year + '"]').prop('checked', true);

      //該当年がない場合
      if(!$('.js-year[value="' + year + '"]').length) {
        $('.js-year:checked').prop('checked', false);
      }
    };

    /**
     *
     */
    $(window).on('hashchange', function() {
      var numberYear = getHashYear();
      // 戻るバックスペース
      if (numberYear === 0) {
        if (queryData.y) {
          numberYear = Number(queryData.y);
        }
        else {
          numberYear = Number($('.yearSelect:first-child .js-year').val());
        }
      }

      buildNewsList(numberYear);
      yearRadioSet(numberYear);
    });

    /**
     * buildPageList
     * 年選択構築
     * @param
     * @retrun
     */
    var buildPageList = function() {
      var xmlFiles = fileList.split(/\s+/);
      $.each(xmlFiles, function(index, value) {
        var year = Number(value.match(/(\d{4}).xml$/)[1]);

        // 2009年以前は掲載しない
        if (year <= 2009) {
          return false;
        }

        var html = '<label class="yearSelect">';
        html += '<input class="js-year" type="radio" name="year" value="' + year + '">';
        html += '<span class="yearSelect__txt">' + year + '</span>';
        html += '</label>';

        $pageList.append(html);
      });

      $('.js-year').on('change', function() {
        setHashYear($(this).val());
      });
    };

    /**
     * getDetailInfo
     * 選択されている「お知らせを種別」を取得する
     * @param
     * @retrun
     */
    var getDetailInformations = function() {
      var detailInformations = [];
      var $checkedItems = $('.js-newsCategory input[type=checkbox]:checked');

      if ($checkedItems.length > 0) {
        // 選択項目のみ抽出
        $.each($checkedItems, function() {
          detailInformations.push($(this).attr('name'));
        });
      }

      return detailInformations.join('|');
    };

    /**
     * checkDetailInformations
     * クエリーで指定されている「お知らせを種別」をチェックする
     * @param
     * @return
     */
    var checkDetailInformations = function() {
      if (!queryData.i) {
        return;
      }
      $.each(queryData.i.split('|'), function(index, value) {
        $detailInformations.filter('[name="' + value + '"]').prop({
          'checked': true
        });
      });
    };

    /**
     * 初期化
     */
    var init = function() {
      // body要素のID属性をクエリーにもとづいて書き換える
      setLocationId($('body'));

      // 年選択構築
      buildPageList();

      // クエリーで指定されている「お知らせを種別」をチェックする
      checkDetailInformations();

      // 1. 一覧 -> 詳細 -> 一覧と遷移した場合、hashを参照して、元開いていた年を表示
      // 2. クエリーに年指定がある場合は、指定年を表示
      // 2.1 2009年以前、もしくは記事xmlが存在しない場合は、最新年を表示
      // 3. 指定なし、指定が無効の場合は、最新年を表示
      var numberYear = getHashYear();

      // 1.の対象外、かつクエリーに年の指定があった場合
      if ((numberYear === 0) && (!!queryData.y)) {
        numberYear = Number(queryData.y);
        numberYear = isNaN(numberYear) ? 0 : numberYear;
      }

      if ((2009 >= numberYear) || ($.inArray('' + numberYear, fileList.replace(/\.xml/gi, '').split(/\s+/)) === -1)) {
        // クエリー、ハッシュ無し、
        // 2009年以前、もしくは有効な年ではなかった場合
        // 最新のリストを表示
        var latestYear = Number($('.yearSelect:first-child .js-year').val());
        buildNewsList(latestYear);
        yearRadioSet(latestYear);
      }
      else {
        // 有効な年の指定の場合
        buildNewsList(numberYear);
        yearRadioSet(numberYear);
      }
    };

    /**
     * 絞りこみボタン動作定義
     */
    $('#iconFilterBtn').on('click', function() {
      buildNewsList(Number($('.js-year:checked').val()));
    });

    init();
  }
}

