import $ from "jquery";
import UA from "../lib/UA";
export default class Accordion {
  constructor() {
    this.triggerElm = document.getElementsByClassName('js-acdTrigger');
    this.ua = new UA();
    this.init();
  }

  init() {
    this.urlCheck();
    this.bindEvents();
    this.hover();
  }

  urlCheck() {
    //アンカー遷移の場合アコーディオン開いた状態になる
    const hash = location.hash.replace('#', '');
    const target = document.getElementById(hash);

    if(target) {
      const targetAcd =  target.getElementsByClassName('js-acdTrigger')[0];

      if(targetAcd) {
        this.toggle(targetAcd);
      }
    }
  }

  bindEvents() {
    const self = this;
    Array.prototype.forEach.call(this.triggerElm , (elm) => {
      elm.addEventListener('click', e => {
        this.toggle(elm);
      });

      elm.addEventListener('keydown', e => {
        if(e.keyCode == 13) {
          this.toggle(elm);
        }
      });
    });

    // アンカー遷移時にアコーディオンを開いた状態にする
    $('a[href^="#"]').on('click',function(e) {
      const href = $(this).attr("href");
      if (!(href == "#" || href == "")) {
        const target = $(href);
        if (target[0]) {
          const acdTrigger = target[0].getElementsByClassName("js-acdTrigger");
          if (acdTrigger.length == 1) {
            self.open(acdTrigger[0]);
          }
        }
      }
    });
  }

  open(elm) {
    elm.classList.add('is-active');
    $(elm.nextElementSibling).slideDown(400)
  }

  toggle(elm) {
    elm.classList.toggle('is-active');
    $(elm.nextElementSibling).slideToggle(400)
  }

  hover() {
    if (this.ua.device() == 'other') {
      Array.prototype.forEach.call(this.triggerElm , (elm) => {
        elm.addEventListener('mouseover', e => {
          elm.parentNode.classList.add('is-hover');
        });
        elm.addEventListener('mouseout', e => {
          elm.parentNode.classList.remove('is-hover');
        });
      });
    }
  }
}

