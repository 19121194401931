import $ from "jquery";
export default class Modal {
  constructor() {
    this.modalContent;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const triggerElm = document.getElementsByClassName('js-modalTrigger');
    const closeElm = document.getElementsByClassName('js-modalClose');
    const modal = document.getElementsByClassName('js-modal');

    Array.prototype.forEach.call(triggerElm, (elm) => {
      elm.addEventListener('click', e => {
        //data属性を取得して該当モーダルを表示する
        const id = elm.dataset.modal;
        this.modalContent = document.getElementById(id);
        this.modalContent.style.display = 'block';
        //モーダル開く
        $(modal).fadeIn(400);
        document.body.classList.add('is-fixed');
      });
    });

    Array.prototype.forEach.call(closeElm, (elm) => {
      elm.addEventListener('click', e => {
        const content = this.modalContent;
        //モーダル閉じる
        $(modal).fadeOut(400, function() {
          //スクロールリセット
          modal[0].classList.add('is-reset');
          content.scrollTop = 0;
          modal[0].classList.remove('is-reset');
          //フェードアウト完了後にコンテンツを非表示にする
          content.style.display = 'none';
        });
        document.body.classList.remove('is-fixed')
      });
    });
  }
}

