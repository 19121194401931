export default class ZoomImg {
  constructor() {
    this.imgHtml = '';
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const triggerElm = document.getElementsByClassName('js-zoom');

    Array.prototype.forEach.call(triggerElm, (elm) => {
      elm.addEventListener('click', e => {
        //spの場合
        if(window.matchMedia('(max-width: 767px)').matches) {
          //imgタグを複製
          this.imgHtml = elm.children[0].cloneNode(false);
          //新しいwindowを開く
          this.winOpen();
        }
      });
    });
  }

  winOpen() {
    let html = '<!DOCTYPE html>';
        html += '<html lang="ja">';
        html += '<head>';
        html += '<meta charset="Shift_JIS">';
        html += '<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">';
        html += '<meta name="viewport" content="width=device-width">';
        html += '<meta name="format-detection" content="telephone=no">';
        html += '<title></title>';
        html += '<link rel="stylesheet" href="/h_common/css/reset.css">';
        html += '<link rel="stylesheet" href="/h_common/css/common.css">';
        html += '</head>';
        html += '<body class="zoomPage">';
        html += '<button class="closeBtn" type="button" onclick="window.close();"><span>閉じる</span></button>';
        html += '</body>';

    const newWin = window.open();
    newWin.document.open();
    newWin.document.write(html);
    newWin.document.close();
    //新しいwindowに複製したimgタグを追加
    newWin.document.querySelector('body').appendChild(this.imgHtml);
  }
}

