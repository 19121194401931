export default class AllHeightSet {
  constructor() {
    this.mql = window.matchMedia('only screen and (max-width: 767px)');
    this.init();
  }

  init() {
    this.bindEvents();

    // ブレイクポイントの瞬間に発火
    const checkBreakPoint = () => {
      this.bindEvents()
    }
    this.mql.addListener(checkBreakPoint);

    //画面回転時
    window.addEventListener('orientationchange', e => {
      setTimeout(()=> {
        this.bindEvents();
      }, 500);
    });
  }

  bindEvents() {
    //高さのスタイルリセット
    const heightItem = document.getElementsByClassName('js-allHeightItem');
    Array.prototype.forEach.call(heightItem, (elm) => {
      elm.style.height = 'auto';
    });

    const triggerElm = document.getElementsByClassName('js-allHeightSet');
    Array.prototype.forEach.call(triggerElm, (elm) => {
      let height = 0;
      //高さ揃えるターゲットを取得
      const item = elm.getElementsByClassName('js-allHeightItem');
      Array.prototype.forEach.call(item, (item) => {
        const itemHeight = item.clientHeight;

        if(itemHeight > height) {
          height = itemHeight;
        }
      });

      //高さを設定
      Array.prototype.forEach.call(item, (item) => {
        item.style.height = height + 'px';
      });

      //heightをリセット
      height = 0;
    });
  }
}

