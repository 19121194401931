import _ from "underscore";
import $ from "jquery";
import StickySidebar from "sticky-sidebar-v2";
import SitePath from "../lib/SitePath";
import GetJson from "../lib/GetJson";
import UA from "../lib/UA";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class SideNav {
  constructor(container) {
    this.target = document.getElementsByClassName(container)[0];
    this.sitePath = new SitePath();
    this.getJson = new GetJson();
    this.ua = new UA();
    this.jsonFile = this.sitePath.getJsonPath();
    this.basePath;
    this.timeout;
    this.init();
  }

  init() {
    if (this.target) {
      this.initList();
    } else {
      this.initStickSidebar();
      this.bindEvents();
    }
  }

  bindEvents() {
    const isPassive = new IsPassiveSupport();

    // pc scroll event
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        this.scroll();
      });
    }
    if (this.ua.device() == "other") {
      document.addEventListener(
        "scroll",
        () => {
          this.onScroll();
        },
        isPassive.check() ? { passive: true } : false
      );
      this.onScroll();
    }
  }

  initList() {
    // jsonFile 取得
    const jsonEvent = this.getJson.get(this.jsonFile);
    jsonEvent.done(data => {
      this.basePath = this.sitePath.getBasePath(data);
      this.createSideNav(data);
      this.initStickSidebar();
      this.bindEvents();
    });
  }

  // sideNavi要素追加
  createSideNav(data) {
    if (this.basePath.category) {
      const ul = document.createElement("ul");
      _.each(data, item => {
        if (this.basePath.category == item.category) {
          if (item.child.length) {
            const listElms = this.createSideNavLevel2(item.child);
            if (listElms) {
              ul.appendChild(listElms);
            }
          }
        }
      });

      this.target.appendChild(ul);
    } else {
      // urlがjsonファイルに存在しない場合
    }
  }

  createSideNavLevel2(data) {
    const fragment = document.createDocumentFragment();

    let matchFlg = false;
    _.each(data, item => {
      if (!matchFlg) {
        if (item.disabled == "") {
          if (this.basePath.level2 == item.url) {
            const li = document.createElement("li");
            const a = document.createElement("a");
            a.classList.add("sideNav__category");
            a.href = item.url;
            if (item.blank || item.login || item.pdf) {
              a.target = "_blank";
            }
            if (item.spIcon) {
              const img = document.createElement("img");
              const src = item.spIcon.replace(/^(.+)(\.[a-z]+)$/, "$1_on$2");
              img.classList.add("sideNav__icon");
              img.src = src;
              img.alt = "";
              a.appendChild(img);
            }
            const span = document.createElement("span");
            span.textContent = item.name;
            a.appendChild(span);

            li.appendChild(a);
            fragment.appendChild(li);

            if (item.child.length) {
              fragment.appendChild(this.createSideNavLevel3(item.child));
            }

            matchFlg = true;
          }
        }
      }
    });

    return fragment;
  }

  createSideNavLevel3(data) {
    const fragment = document.createDocumentFragment();

    _.each(data, item => {
      if (item.disabled == "") {
        const li = document.createElement("li");
        if (this.basePath.level3 == item.url) {
          li.classList.add("is-current");
        }

        const a = document.createElement("a");
        a.classList.add("sideNav__item");
        a.href = item.url;
        a.textContent = item.name;
        if (item.blank || item.login || item.pdf) {
          a.target = "_blank";
        }
        if (item.blank) a.classList.add("sideNav__item--blank");
        if (item.login) a.classList.add("sideNav__item--login");
        if (item.pdf) a.classList.add("sideNav__item--pdf");

        li.appendChild(a);

        if (this.basePath.level3 == item.url) {
          if (item.child.length) {
            li.appendChild(this.createSideNavLevel4(item.child));
          }
        }

        fragment.appendChild(li);
      }
    });

    return fragment;
  }

  createSideNavLevel4(data) {
    const ul = document.createElement("ul");

    _.each(data, item => {
      if (item.disabled == "") {
        const li = document.createElement("li");
        if (this.basePath.level4 == item.url) {
          li.classList.add("is-current");
        }

        const a = document.createElement("a");
        a.classList.add("sideNav__item");
        a.href = item.url;
        a.textContent = item.name;
        if (item.blank || item.login || item.pdf) {
          a.target = "_blank";
        }
        if (item.blank) a.classList.add("sideNav__item--blank");
        if (item.login) a.classList.add("sideNav__item--login");
        if (item.pdf) a.classList.add("sideNav__item--pdf");

        li.appendChild(a);
        ul.appendChild(li);
      }
    });

    return ul;
  }

  heightSet() {
    const target = document.getElementsByClassName('sideMenu__inner')[0]
    target.style.height = 'auto';

    const height =  Math.ceil($('.sideMenu__inner').outerHeight());
    target.style.height = height + 'px';
  }

  initStickSidebar() {
    const globalNavi = document.getElementsByClassName("js-globalNavi")[0];
    let topSpacing = (globalNavi) ? 120 : 0;

    if (!document.getElementsByClassName("js-naviSubMenu").length) {
      topSpacing = 60;
    }

    if (
      document.getElementsByClassName('contents--col2')[0] &&
      document.getElementsByClassName('sideMenu__inner')[0]
    ) {
      this.heightSet();

      const fontChangeTarget = document.getElementsByClassName('js-fontChangeTarget');
      Array.prototype.forEach.call(fontChangeTarget, elm => {
        elm.addEventListener('click', e => {
          setTimeout(()=> {
            this.heightSet();
          }, 500);
        });
      });

      const sidebar = new StickySidebar('.sideMenu', {
        topSpacing: topSpacing,
        bottomSpacing: 40,
        containerSelector: '.contents--col2',
        innerWrapperSelector: '.sideMenu__inner',
        minWidth: 768
      });
    }
  }

  scroll() {
    const sideMenu = document.getElementsByClassName("sideMenu")[0];
    const sideMenuInner = document.getElementsByClassName("sideMenu__inner")[0];
    if (sideMenu && sideMenuInner) {
      const sideMenuRect = sideMenu.getBoundingClientRect();
      if (sideMenuInner.style.position == "fixed") {
        sideMenuInner.style.left = sideMenuRect.left + "px";
      }
    }
  }
}
