export default class ServiceBg {
  constructor() {
    this.init();
  }

  init() {
    const targetList = document.getElementsByClassName('js-serviceBlockImage');

    if (targetList.length) {
      Array.prototype.forEach.call(targetList, elm => {
        const imageElm = elm.getElementsByTagName('img')[0];
        if (imageElm) {
          const src = imageElm.src;
          const style = 'background-image: url(' + src + ')';
          elm.setAttribute('style', style);
        }
      });
    }
  }
}
