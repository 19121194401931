/*
 * サイト内メニュー周りの処理
 * jsonFile = "/h_common/json/menulist.json";
 */

import _ from "underscore";

export default class SitePath {
  constructor() {}

  getJsonPath() {
    return "/h_common/json/menulist.json";
  }

  // 基準パスを返却
  getBasePath(data) {
    let path = location.pathname;
    path = path.replace("index.html", "");

    this.currentPath = "";
    this.getCurrentPath(data, path);

    this.resetBasePath();
    this.setBasePath(data, this.currentPath);

    if (!this.basePath.matchLevel) {
      // パスが一致しないは初期化
      this.resetBasePath();
    }

    // matchLevel以降を削除
    if (this.basePath.matchLevel == 1) {
      this.basePath.level2 = "";
      this.basePath.level3 = "";
      this.basePath.level4 = "";
    } else if (this.basePath.matchLevel == 2) {
      this.basePath.level3 = "";
      this.basePath.level4 = "";
    } else if (this.basePath.matchLevel == 3) {
      this.basePath.level4 = "";
    }

    // disabled削除処理
    this.deleteDisabledPath(data, 1);

    // matchLevel途中が空の場合
    if (this.basePath.level1 == "") {
      this.basePath.level2 = "";
      this.basePath.level3 = "";
      this.basePath.level4 = "";
    } else if (this.basePath.level2 == "") {
      this.basePath.level3 = "";
      this.basePath.level4 = "";
    } else if (this.basePath.level3 == "") {
      this.basePath.level4 = "";
    }

    return this.basePath;
  }

  resetBasePath() {
    this.basePath = {
      category: "",
      matchLevel: "",
      level1: "",
      level2: "",
      level3: "",
      level4: ""
    };
  }

  // 最初に一致したurlのcurrentの値をthis.currentPathに設定
  getCurrentPath(data, path) {
    _.each(data, item => {
      if (!this.currentPath) {
        if (item.url == path) {
          this.currentPath = item.current;
        } else {
          if (item.child.length) {
            this.getCurrentPath(item.child, path);
          }
        }
      }
    });
  }

  setBasePath(data, path) {
    _.each(data, item => {
      if (item.disabled) return false;
      if (!this.basePath.matchLevel) {
        this.basePath["level" + item.level] = item.url;
        if (path != "" && item.url == path) {
          this.basePath.category = item.category;
          this.basePath.matchLevel = item.level;
        } else {
          if (item.child.length) {
            this.setBasePath(item.child, path);
          }
        }
      }
    });
  }

  deleteDisabledPath(data, level) {
    let matchFlg = false;
    _.each(data, item => {
      if (!matchFlg) {
        if (item.url == this.basePath["level" + level]) {
          matchFlg = true;
          if (item.disabled) {
            this.basePath["level" + level] = "";
          }
          if (item.child.length) {
            this.deleteDisabledPath(item.child, level+1);
          }
        }
      }
    });
  }
}
