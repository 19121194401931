import $ from "jquery";
import _ from "underscore";
import slick from "slick-carousel";

export default class CustomSlide {
  constructor(container, centerPaddingSp) {
    this.container = document.getElementsByClassName(container)[0];
    this.sliderStopFlg = false;
    this.centerPaddingSp = centerPaddingSp;
    this.slideCount;
	  this.breakPoint = 768;
    this.init();
  }

  init() {
    this.initSlider();
  }

  initSlider() {
    const targetElm = this.container.getElementsByClassName('js-customSlideTarget')[0];
    const slideBarElm = this.container.getElementsByClassName('js-customSlideBar')[0];
    const stopBtnElm = this.container.getElementsByClassName('js-customSlideStop')[0];
    const prevBtnElm = this.container.getElementsByClassName('js-customSlidePrev')[0];
    const nextBtnElm = this.container.getElementsByClassName('js-customSlideNext')[0];

    const slickOpt = {
      slidesToShow: 1,
      fade: false,
      autoplay: true,
      dots: false,
      arrows: false,
      centerMode: true,
      autoplaySpeed: 3000,
      initialSlide: 0,
      pauseOnFocus: false,
      pauseOnHover: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: this.centerPaddingSp,
          }
        }
      ]
    };

    $(targetElm).on('init', (event, slick, currentSlide, nextSlide) => {
      this.slideCount = slick.slideCount;
      const barWidth = 100 / slick.slideCount;
      slideBarElm.style.width = barWidth + "%";
    });

    $(targetElm).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      const nextPos = (100 / this.slideCount) * nextSlide;
      slideBarElm.style.left = nextPos + "%";
    });

    const slider = $(targetElm).slick(slickOpt);

    const stopSlider = () => {
      if (!this.sliderStopFlg) {
        slider.slick('slickSetOption', 'autoplay', false).slick('slickPause');
      }
      stopBtnElm.classList.add('-stop');
      this.sliderStopFlg = true;
    }

    const startSlider = () => {
      if (this.sliderStopFlg) {
        slider.slick('slickSetOption', 'autoplay', true).slick('slickPlay');
      }
      stopBtnElm.classList.remove('-stop');
      this.sliderStopFlg = false;
    }

    this.debouncedOnStop = _.debounce(() => {
      if (this.sliderStopFlg) {
        startSlider();
      } else {
        stopSlider();
      }
    }, 0);
    stopBtnElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnStop();
    });

    this.debouncedOnPrev = _.debounce(() => {
      slider.slick('slickPrev');
    }, 0);
    prevBtnElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnPrev();
    });

    this.debouncedOnNext = _.debounce(() => {
      slider.slick('slickNext');
    }, 0);
    nextBtnElm.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      this.debouncedOnNext();
    });
  }
}
