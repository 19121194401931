export default class HeightSet {
  constructor() {
    this.mql = window.matchMedia('only screen and (max-width: 767px)');
    this.init();
  }

  init() {
    this.bindEvents();

    // ブレイクポイントの瞬間に発火
    const checkBreakPoint = () => {
      this.bindEvents()
    }
    this.mql.addListener(checkBreakPoint);

    //画面回転時
    window.addEventListener('orientationchange', e => {
      setTimeout(()=> {
        this.bindEvents();
      }, 500);
    });
  }

  bindEvents() {
    //高さのスタイルリセット
    const heightItem = document.getElementsByClassName('js-heightItem');
    Array.prototype.forEach.call(heightItem, (elm) => {
      elm.style.height = 'auto';
    });

    const triggerElm = document.getElementsByClassName('js-heightSet');
    Array.prototype.forEach.call(triggerElm, (elm) => {
      //カラム数を取得
      let col;
      if (this.mql.matches) {
        // SP
        col = Number(elm.dataset.sp);
      } else {
        // PC
        col = Number(elm.dataset.pc);
      }

      let height = 0;
      //子要素を取得
      const box = elm.getElementsByClassName('js-box');
      //行数
      const maxLine = box.length / col + 1;
      //高さ揃えるターゲット数を取得
      const itemCount = box[0].getElementsByClassName('js-heightItem').length;

      //itemCountの数だけループ
      for (let index = 0; index < itemCount; index++) {
        //行ごとにループ
        for (let line = 1; line < maxLine; line++) {
          //カラムのカウントの基準設定
          const count = line * col - col;
          //最大の高さを取得
          for (let boxIndex = count; boxIndex <= (count + col - 1); boxIndex++) {
            if(box[boxIndex]) {
              const item = box[boxIndex].getElementsByClassName('js-heightItem')[index];
              const itemHeight = item.clientHeight;

              if(itemHeight > height) {
                height = itemHeight;
              }
            }
          }

          //高さを設定
          for (let boxIndex = count; boxIndex <= (count + col - 1); boxIndex++) {
            if(box[boxIndex]) {
              box[boxIndex].getElementsByClassName('js-heightItem')[index].style.height = height + 'px';
            }
          }

          //heightをリセット
          height = 0;
        }
      }
    });
  }
}

