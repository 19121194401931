import UA from "../lib/UA";
export default class Contact {
  constructor() {
    this.ua = new UA();
    this.init();
  }

  init() {
    if (this.ua.device() !== 'sp') {
      this.bindEvents();
    }
  }

  bindEvents() {
    const tel = document.querySelectorAll('a[href^="tel:"]');

    Array.prototype.forEach.call(tel , (elm) => {
      elm.addEventListener('click', e => {
        e.preventDefault();
      });
    });
  }
}

