import _ from "underscore";
import SitePath from "../lib/SitePath";
import GetJson from "../lib/GetJson";
import Anchor from "./Anchor";

export default class SiteMap {
  constructor(container) {
    this.target = document.getElementsByClassName(container)[0];
    this.sitePath = new SitePath();
    this.jsonFile = this.sitePath.getJsonPath();
    this.getJson = new GetJson();
    this.anchorNum = 1;
    this.anchor = new Anchor();
    this.init();
  }

  init() {
    this.initSiteMap();
  }

  initSiteMap() {
    // jsonFile 取得
    const jsonEvent = this.getJson.get(this.jsonFile);
    jsonEvent.done(data => {
      this.createSiteMap(data);
      this.anchor.loadEvents();
    });
  }

  createSiteMap(data) {
    const fragment = document.createDocumentFragment();
    const parentElm = this.target.parentNode;

    _.each(data, item => {
      if (item.disabled == "") {
        fragment.appendChild(this.createSiteMapLevel1(item));
      }
    });

    parentElm.insertBefore(fragment, this.target.nextSibling);
    parentElm.removeChild(this.target);
  }

  createSiteMapLevel1(data) {
    const section = document.createElement("section");
    section.classList.add("section");

    const h2 = document.createElement("h2");
    h2.classList.add("h2");
    h2.setAttribute("id", "anchor0" + this.anchorNum++);

    if (data.url) {
      const link = document.createElement("a");
      link.classList.add("h2__link");
      link.href = data.url;
      link.textContent = data.name;
      h2.appendChild(link);
    } else {
      h2.textContent = data.name;
    }
    section.appendChild(h2);

    if (data.child.length) {
      _.each(data.child, item => {
        if (item.disabled == "") {
          section.appendChild(this.createSiteMapLevel2(item));
        }
      });
    }

    return section;
  }

  createSiteMapLevel2(data) {
    const fragment = document.createDocumentFragment();

    const h3 = document.createElement("h3");
    h3.classList.add("h3");

    const link = document.createElement("a");
    link.classList.add("h3__link");
    link.href = data.url;
    if (data.blank) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h3__linkIcon" src="/h_common/css/image/icon_blank.png" alt="">';
    } else if (data.login) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h3__linkIcon" src="/h_common/css/image/icon_ex.png" alt="">';
    } else if (data.pdf) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h3__linkIcon" src="/h_common/css/image/icon_pdf.png" alt="">';
    } else {
      link.textContent = data.name;
    }

    h3.appendChild(link);
    fragment.appendChild(h3);

    if (data.child.length) {
      const col2 = document.createElement("div");
      col2.classList.add("col2");

      _.each(data.child, item => {
        if (item.disabled == "") {
          col2.appendChild(this.createSiteMapLevel3(item));
        }
      });

      fragment.appendChild(col2);
    }

    return fragment;
  }

  createSiteMapLevel3(data) {

    const inner = document.createElement("div");

    const h4 = document.createElement("h4");
    h4.classList.add("h4");

    const link = document.createElement("a");
    link.classList.add("h4__link");
    link.href = data.url;
    if (data.blank) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h4__icon" src="/h_common/css/image/icon_blank.png" alt="">';
    } else if (data.login) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h4__icon" src="/h_common/css/image/icon_ex.png" alt="">';
    } else if (data.pdf) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="h4__icon--pdf" src="/h_common/css/image/icon_pdf.png" alt="">';
    } else {
      link.textContent = data.name;
    }

    h4.appendChild(link);
    inner.appendChild(h4);

    if (data.child.length) {
      let validLength = data.child.length;
      _.each(data.child, item => {
        if (item.disabled) validLength--;
      });

      if (validLength) {
        const ul = document.createElement("ul");
        ul.classList.add("linkList");

        _.each(data.child, item => {
          if (item.disabled == "") {
            ul.appendChild(this.createSiteMapLevel4(item));
          }
        });

        inner.appendChild(ul);
      }
    }

    return inner;
  }

  createSiteMapLevel4(data) {
    const li = document.createElement("li");

    const link = document.createElement("a");
    link.classList.add("linkList__link");
    link.href = data.url;

    if (data.blank) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="linkList__icon" src="/h_common/css/image/icon_blank.png" alt="">';
    } else if (data.login) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="linkList__icon" src="/h_common/css/image/icon_ex.png" alt="">';
    } else if (data.pdf) {
      link.setAttribute("target", "_blank");
      link.innerHTML = data.name + '<img class="linkList__icon--pdf" src="/h_common/css/image/icon_pdf.png" alt="">';
    } else {
      link.textContent = data.name;
    }

    li.appendChild(link);

    return li;
  }
}
