import $ from "jquery";
export default class Overlay {
  constructor() {
    this.imgHtml = '';
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const triggerElm = document.getElementsByClassName('js-scrollOverlay');

    Array.prototype.forEach.call(triggerElm, (elm) => {
      elm.addEventListener('touchstart', e => {
        //非表示に
        $(elm).fadeOut(400)
      });

      elm.addEventListener('click', e => {
        //非表示に
        $(elm).fadeOut(400)
      });
    });
  }
}

