import $ from "jquery";
export default class Anchor {
  constructor() {
    this.init();
  }

  init() {
    if (!document.getElementsByClassName('js-siteMap').length) {
      //サイトマップページ以外 ロード時にハッシュがついていたらアンカー遷移
      this.loadEvents();
    }
    this.bindEvents();
  }

  loadEvents() {
    //お知らせ一覧ページでイコール付きのハッシュがあるのでSyntax errorにならないようにする
    const hash = location.hash.replace('=', '');

    if ($(hash).length) {
      const headerH = window.matchMedia('(max-width: 767px)').matches ? 54 : 120;
      const position = $(location.hash).offset().top - headerH;
      $('body,html').animate({scrollTop:position}, 0);
    }
  }

  bindEvents() {
    $('a[href^="#"]').on('click',function(e) {
      const headerH = window.matchMedia('(max-width: 767px)').matches ? 54 : 120;
      const href = $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top - headerH;
      $('body,html').animate({scrollTop:position}, 300, 'swing');
    });
  }
}

