import $ from "jquery";
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class FixedDirect {
  constructor() {
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();

    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
      }
      this.timeout = requestAnimationFrame(() => {
        self.scroll();
      });
    }
    document.addEventListener(
      "scroll",
      () => {
        this.onScroll();
      },
      isPassive.check() ? { passive: true } : false
    );
    this.scrollPos = 0;
    this.onScroll();
  }

  scroll() {
    const bottomPoint = document.body.clientHeight - screen.height;
    const gotoTopElm = document.getElementsByClassName('js-fixedDirect')[0];
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    scrollTop = Math.ceil(scrollTop);

    if (bottomPoint <= scrollTop) {
      gotoTopElm.classList.add('-hide');
    } else {
      gotoTopElm.classList.remove('-hide');
    }
  }
}
