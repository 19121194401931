export default class UA {
  constructor() {}

  device() {
    const ua = navigator.userAgent.toLowerCase();
    if (
      ua.indexOf('iphone') > 0 ||
      ua.indexOf('ipod') > 0 ||
      (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
    ) {
      return 'sp';
    } else if (
      ua.indexOf('ipad') > -1 ||
      ( ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ||
      ua.indexOf('android') > 0
    ) {
      return 'tab';
    } else {
      return 'other';
    }
  }
}
