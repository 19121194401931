import _ from "underscore";

export default class PageSearchForm {
  constructor() {
    this.form = document.getElementsByClassName('js-pageSearchForm');
    this.defaultText = '';
    this.init();
  }

  init() {
    this.setDefaultValue();
    this.bindEvents();
  }

  bindEvents() {
    Array.prototype.forEach.call(this.form, formElm => {
      const inputElm = formElm.getElementsByClassName('js-pageSearchFormInput')[0];

      // blur event
      inputElm.addEventListener('blur', () => {
        const inputText = inputElm.value;
      });

      // focus event
      inputElm.addEventListener('focus', () => {
        const inputText = inputElm.value;
      });

      // submit event
      formElm.addEventListener('submit', e => {
        e.preventDefault();
        e.stopPropagation();

        const inputText = inputElm.value;

        if (inputText == '') {
        } else {
          formElm.submit();
        }
      });
    });
  }

  setDefaultValue() {
    Array.prototype.forEach.call(this.form, formElm => {
      const inputElm = formElm.getElementsByClassName('js-pageSearchFormInput')[0];
      inputElm.value = this.defaultText;
    });
  }
}
