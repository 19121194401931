import $ from "jquery";
export default class SimpleAccordion {
  constructor() {
    this.triggerElm = document.getElementsByClassName('js-simpleAcdTrigger');
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    Array.prototype.forEach.call(this.triggerElm , (elm) => {
      elm.addEventListener('click', e => {
        this.toggle(elm);
      });
    });
  }

  toggle(elm) {
    elm.classList.toggle('is-active');
    $(elm.previousElementSibling).slideToggle(400);
  }
}

